import React, { useEffect, useState } from "react";
import ReactPlayer from 'react-player'
import { useStyles } from "./styles";
import Fade from '../Common/Fade'

const VideoFormat = ({ url, onArtworkReady }) => {
    const [isFirstLoad, setIsFirstLoad] = useState(true)

    const classes = useStyles()

    const wrapStyle = {
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        top: 0,
        left: 0,
        display: !isFirstLoad ? 'block' : 'none'
    }

    const videoStyle = {
        objectFit: 'cover',
        width: '100vw',
        height: '100vh'
    }

    const artworkReady = () => {
        if (isFirstLoad) {
            onArtworkReady()
            setIsFirstLoad(false)
        }
    }

    useEffect(() => {
        setIsFirstLoad(true)
    }, [url])

    return (
        <>
            <ReactPlayer
                style={wrapStyle}
                url={url}
                onReady={artworkReady}
                muted
                playing
                width="100%"
                height="100%"
                playsinline
                loop
                config={{
                    file: {
                        attributes: {
                            style: videoStyle,
                        },
                    },
                }}
            />
            <Fade show={isFirstLoad}>
                <div className={classes.videoWrap} />
            </Fade>
        </>
    );
};

export default VideoFormat;
