import { createMuiTheme, makeStyles } from "@material-ui/core";

import "@fontsource/nunito-sans/700.css";
import "@fontsource/nunito-sans/400.css";

export const useStyles = makeStyles((theme) => {
    return {
        bgNoise: {
            transition: "all .4s",
            position: 'fixed',
            height: '100%',
            width: '100%',
            left: 0,
            top: 0,

            "& img": {
                transform: 'translate(-50%, -50%)',
                transition: "all .4s",
                visibility: "hidden",
                position: 'absolute',
                minHeight: '100%',
                minWidth: '100%',
                left: '50%',
                top: '50%',
                opacity: 0,
            },
            "&.active": {
                "& img": {
                    visibility: "visible",
                    opacity: 1,
                },
            },
        },

        checkPaired: {
            justifyContent: "center",
            transition: "all .4s",
            alignItems: "center",
            visibility: "hidden",
            position: "absolute",
            display: "flex",
            height: 150,
            opacity: 0,

            "&.active": {
                visibility: "visible",
                position: "static",
                opacity: 1,
            }
        },

        wrapper: {
            position: "absolute",
            visibility: "hidden",
            textAlign: "center",
            minHeight: "100vh",
            padding: "60px 0",
            opacity: 0,
            right: 0,
            left: 0,

            "&.active": {
                visibility: "visible",
                position: "relative",
                opacity: 1,
            }
        },

        textWrapper: {
            margin: "0 auto",
            height: "50vh",
            minHeight: 390,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },

        input: {
            background: "rgba(255,255,255,.15)",
            padding: "0 !important",
            textAlign: "center",
            borderRadius: 16,
            color: "white",
            marginTop: 40,
            width: 190,
            "&:hover, &:focus, &:active": {
                background: "rgba(255,255,255,.25)",
            },
        },

        textInput: {
            textAlign: "center",
            padding: theme.spacing(2.5, 2),
        },

        animationWrapper: {
            justifyContent: 'center',
            flexDirection: "column",
            transition: "all .4s",
            alignItems: 'center',
            position: "relative",
            display: 'flex',
            minHeight: 0,
        },

        animation: {
            transition: "all .4s",
            position: "absolute",
            visibility: 'hidden',
            opacity: 0,
            right: 0,
            left: 0,

            "&.active": {
                animation: `$zoom .4s ${theme.transitions.easing.easeInOut}`,
                visibility: 'visible',
                position: 'static',
                opacity: 1,
            }
        },

        textHelper: {
            textAlign: "center"
        },
        card: {
            background: "transparent",
            margin: theme.spacing(2),
            maxWidth: 124,
            marginTop: 0,
            cursor: "pointer",
            "& :hover": {
                // transform: 'scale(1.01)',
                // boxShadow: '0px 0px 10px #000',
                // transition: 'all 200ms'
            }
        },

        cardImage: {
            backgroundColor: '#000',
            position: 'relative',
            overflow: 'hidden',
            borderRadius: 20,
            height: 124,
            width: 124,

            "& img": {
                transform: 'translate(-50%, -50%)',
                position: 'absolute',
                minHeight: '100%',
                minWidth: '100%',
                height: '100%',
                left: '50%',
                top: '50%',

                "&[data-portrait='1']": {
                    height: 'unset',
                    width: '100%',
                }
            }
        },

        cardLoading: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#000',
        },

        skeleton: {
            backgroundColor: "rgb(255,255,255,.11) !important",
        },

        videoThumb: {
            objectFit: 'cover',
            width: '100%',
            height: '100%'
        },
        websiteThumb: {
            border: 0,
            width: '100%',
            height: '100%',
            transform: 'scale(.1)'
        },

        "@keyframes zoom": {
            "0%": {
                opacity: 0,
            },
            "100%": {
                opacity: 1,
            }
        }
    };
}, {index: 1});

export const customTheme = createMuiTheme({
    typography: {
        fontFamily: "Nunito Sans",
        h1: {
            fontSize: 24,
            color: "#ffffff",
            fontWeight: 700,
            marginBottom: 30,
        },

        h2: {
            fontSize: 36,
            color: "#ffffff",
            fontWeight: 700,
        },

        h6: {
            color: "white",
            fontSize: 18,
            marginTop: 20,
        },
        subtitle1: {
            color: "#9D9D9D",
            fontWeight: 400,
            fontSize: 18,
            marginTop: 10,
            lineHeight: 1.2,
        },

        body1: {
            color: "#ffffff",
            fontSize: 16,
            fontWeight: 400,
            lineHeight: "27px",
        },
    },
});
