import { makeStyles } from "@material-ui/core";
import React from "react";
import logoLight from '../../assets/svgs/logo-light.svg'
const useStyles = makeStyles({

  container: {
    justifyContent: "center",
    transition: "all 1s",
    alignItems: "center",
    visibility: "hidden",
    background: "black",
    position: "fixed",
    display: "flex",
    height: "100%",
    width: "100%",
    opacity: 0,
    left: 0,
    top: 0,

    "&.active": {
      visibility: "visible",
      opacity: 1,
    }
  },
  "@keyframes logo-bounce": {
    "0%": {
      opacity: ".5",
    },
    "100%": {
      opacity: "1",
    }
  },

  logoBounce: {
    animation: "$logo-bounce infinite 1s ease-in-out alternate"
  }
});

function Loading({ className }) {
  const classes = useStyles();

  return (
    <div className={[classes.container, className].join(" ")}>
      <img className={classes.logoBounce} src={logoLight} alt="loading..." />
    </div>
  );
}

export default Loading;
