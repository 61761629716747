import logger from 'redux-logger'
import { channelsReducer } from "./channels.slice";
import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { deviceReducer } from "./device.slice";

const middleware = getDefaultMiddleware()
const { NODE_ENV } = process.env
if (NODE_ENV !== 'production') {
	middleware.push(logger)
}

const store = configureStore({
    reducer: {
        channels: channelsReducer,
        device: deviceReducer
    },
    middleware: middleware,
    devTools: process.env.NODE_ENV === "development"
});

export default store;
