import ZF from '@zeroframe/zeroframe-jssdk';

// // to use a different server, pass option to constructor
const zeroFrameApi = new ZF({
  // api_base: 'https://zeroframe.art:4000/v0/',
  api_base: process.env.REACT_APP_API_BASE,
  pubsub_url: process.env.REACT_APP_PUBSUB_URL,
  api_key: process.env.REACT_APP_API_KEY
});

export default zeroFrameApi;