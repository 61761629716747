import { CircularProgress } from "@material-ui/core";

import { useStyles } from "./styles";

const Pairing = ({ className }) => {
  const classes = useStyles();

  return (
    <div className={[classes.checkPaired, className].join(" ")}>
      <CircularProgress />
    </div>
  );
};

export default Pairing;
