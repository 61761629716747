import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { deviceActions } from "../../redux/device.slice";
import zeroFrameApi from "../../lib/zeroFrameApi";
import Loading from "../Home/Loading";

const Pair = () => {
  const { isLoading } = useSelector((state) => state.device),
    dispatch = useDispatch();

  const params = useParams()
  const history = useHistory()
  const goHome = useCallback((deviceId) => history.push(`/`), [history]);
  const goToDevice = useCallback((deviceId) => history.push(`/artwork/${deviceId}`), [history]);

  const pairDevice = async () => {
    try {
      const device = await zeroFrameApi.devices.pair(params.code)
      window.localStorage.setItem("DEVICE_ID", device.id);
      dispatch(deviceActions.updateDevice(device))
      goToDevice(device.id)
    } catch(e) {
      goHome()
    }
  }

  /**
   * Fetch devices data
   */
  useEffect(() => {
    pairDevice();
    // eslint-disable-next-line
  }, []);



  return (
    <>
      {isLoading && <Loading />}
    </>
  );
};

export default Pair;
