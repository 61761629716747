import React, { useEffect, useState } from "react";
import { preloadImage } from '../../lib/functions'
import { useStyles } from "./styles";
import Fade from '../Common/Fade'

const ImageFormat = ({ url, onArtworkReady }) => {
    const [ready, setReady] = useState(false)
    const classes = useStyles()
    useEffect(() => {
        setReady(false)
        preloadImage(url).then(() => {
            setReady(true)
            onArtworkReady()
        }).catch((e) => {
            console.error('issue loading...', url)
        })
        // eslint-disable-next-line
    }, [url]);

    const style = {
        backgroundImage: `url('${url}')`,
        display: ready ? 'block' : 'none'
    }

    return (
        <>
            <Fade show={ready}>
                <div className={classes.image} style={style} />
            </Fade>
        </>
    );
};

export default ImageFormat;
