import { useDispatch, useSelector } from "react-redux";
import React, { useCallback, useEffect } from "react";
import { useHistory } from "react-router";
import { Button, Grid } from "@material-ui/core";

import { channelsActions, channelsSelectors } from "../../redux/channels.slice";
import { deviceActions } from "../../redux/device.slice";
import zeroFrameApi from "../../lib/zeroFrameApi";
import SkeletonLoader from "./SkeletonLoader";
import { get } from "../../lib/functions";
import ChannelCard from "./ChannelCard";

const Channels = () => {
  const { isLoading, hasError } = useSelector(
    (state) => state.channels
  );


  const channels = useSelector(channelsSelectors.selectAll)
  const { deviceInfo } = useSelector((state) => state.device);

  const dispatch = useDispatch();

  const history = useHistory()
  const goToDevice = useCallback((deviceId) => history.push(`/artwork/${deviceId}`), [history]);

  const getPublicChannels = useCallback(
    () => {
      dispatch(channelsActions.getPublicChannels()).then(({ payload: channels }) => {
        channels.forEach(channel => {
          zeroFrameApi.ps.subscribeToChannelUpdated(channel.sharingCode, (channel) => {
            dispatch(channelsActions.channelUpdated({
              id: channel.id,
              changes: channel,
            }))
          })
        })
      })
    },
    [dispatch]
  );

  /**
   * Listen to devices
   */
  const listenToChannel = ({ sharingCode }) => {
    zeroFrameApi.devices
      .listenTo(deviceInfo.id, sharingCode)
      .then((device) => {
        dispatch(deviceActions.updateDevice(device));
        goToDevice(deviceInfo.id)
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getPublicChannels();
  }, [getPublicChannels]);

  return (
    <Grid
      {...(hasError ? { justifyContent: "center" } : {})}
      container
      direction="row"
      justify="center"
    >
      {isLoading ? (
        <SkeletonLoader />
      ) : hasError ? (
        <Button onClick={getPublicChannels} variant="contained">
          Reload
        </Button>
      ) : (
        channels.map((ch) => (
          <ChannelCard
            onClick={listenToChannel.bind(null, ch)}
            src={get(ch, "artwork.url")}
            mediaType={get(ch, "artwork.mediaType")}
            name={ch.name}
            key={ch.id}
            id={ch.id}
          />
        ))
      )}
    </Grid>
  );
};

export default Channels;
