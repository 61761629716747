import { Grid, Typography, Box, ThemeProvider } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useRef } from "react";
import React from "react";

import { customTheme, useStyles } from "./styles";
import Instructions from "./Instructions";
import GettingReady from "./GettingReady";
import Channels from "./Channels";
import Pairing from "./Pairing";
import Final from "./Final";

const Content = ({ className }) => {
  const deviceInfo = useSelector((state) => state.device.deviceInfo),
    { checkPaired } = useSelector((state) => state.device),
    classes = useStyles(),
    parentRef = useRef();

  const isDevicePaired = !!Object.keys(deviceInfo).length;

  return (
    <ThemeProvider theme={customTheme}>
      <Grid
        className={[classes.wrapper, className].join(" ")}
        container
        justify="center"
        alignItems="center"
      >
        <Grid item xs={10} md={12} className={classes.textWrapper}>
          <Box className={classes.animationWrapper} ref={parentRef} width={1}>
            {[
              { Component: Pairing, active: checkPaired },
              {
                Component: Instructions,
                active: !isDevicePaired && !checkPaired,
              },
              {
                Component: GettingReady,
                active: isDevicePaired && !deviceInfo.artwork && !checkPaired,
              },
              { Component: Final, active: deviceInfo.artwork && !checkPaired },
            ].map(({ Component, active }, i) => (
              <Component
                className={[classes.animation, active ? "active" : ""].join(
                  " "
                )}
                key={i}
              />
            ))}
          </Box>
        </Grid>
        {/* TODO: once we have channel 'preview' working, we can show channels before device is paired */}
        { isDevicePaired && (
          <Grid item xs={12}>
            <Grid container justify="center" alignItems="center">
              <Grid item xs={8}>
                <Typography variant="h1">
                  Public channels
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Channels />
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </ThemeProvider>
  );
};

export default Content;
