import { TextField, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import React, { useRef, useEffect, useCallback } from "react";
import store from '../../redux/store.js'
import { deviceActions } from "../../redux/device.slice";
import zeroFrameApi from "../../lib/zeroFrameApi";
import { useStyles } from "./styles";

let timer;

const Instructions = (props) => {
  const { isPairing, errorMessage, hasError } = useSelector(
      (state) => state.device.pairing
    ),
    [code, setCode] = React.useState(""),
    dispatch = useDispatch(),
    inputRef = useRef(null),
    classes = useStyles();

  // TODO: this doesn't work since the Instructions component is rendered early, then animated in.
  useEffect(() => {
    inputRef.current.focus()
  })

  const history = useHistory()
  const goToDevice = useCallback((deviceId) => history.push(`/artwork/${deviceId}`), [history]);

  /**
   * On change pairing code
   */
  const onChangeCode = ({ target: { value: code } }) => {
    setCode(code);

    if (timer) clearTimeout(timer);
    if (code.length === 6)
      timer = setTimeout(pairDevice.bind(null, code), 1000);
  };

  /**
   * Pair device using code
   */
  const pairDevice = (code) => {
    dispatch(deviceActions.pairDevice(code)).then(({ payload }) => {
      if (payload && payload.id) {
        // zeroFrameApi.ps.connect();
        zeroFrameApi.ps.subscribeToDeviceUpdated(payload.id, (data) => {
          const state = store.getState()
          const deviceInfo = state.device.deviceInfo
          if (deviceInfo.artwork && data.artwork && deviceInfo.artwork.url !== data.artwork.url) {
            // artwork has changed
            // setLoading(true);
            setTimeout(() => {
              dispatch(deviceActions.updateDevice(data))
              goToDevice(payload.id)
              // setLoading(false);
            }, 3000);
          } else {
            dispatch(deviceActions.updateDevice(data))
          }
        });
      }
    });
  };

  return (
    <div {...props}>
      <Typography variant="h1">Push artwork to this device</Typography>
      <Typography variant="subtitle1">
        1. Download the{" "}
        <a
          style={{
            textDecoration: "underline",
            color: "#9D9D9D",
          }}
          rel="noopener noreferrer"
          target="_blank"
          href="https://chrome.google.com/webstore/detail/zeroframe/dbpjjmdlacaafmhobemkmgdooaggliod?hl=en"
        >
          Chrome extension
        </a>{" "}
        on your computer
      </Typography>
      <Typography variant="subtitle1">2. Enter pairing code:</Typography>
      <TextField
        inputRef={inputRef}
        InputProps={{
          // ref: inputRef,
          classes: {
            input: classes.textInput,
            root: classes.input,
          },
          disableUnderline: true,
          inputProps: {
            maxLength: 6,
            autoFocus: true
          },
        }}
        FormHelperTextProps={{
          classes: {
            root: classes.textHelper,
          },
        }}
        helperText={errorMessage}
        onChange={onChangeCode}
        disabled={isPairing}
        error={hasError}
        value={code}
      />
    </div>
  );
};

export default Instructions;
