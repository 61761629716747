import React, { useState, useEffect } from "react";
import { Typography, CircularProgress } from "@material-ui/core";
import PreloadImage from "./PreloadImage";

import { useStyles } from "./styles";

function ChannelCard({ src, mediaType, name, id, onClick }) {
  const classes = useStyles();

  const [artSrc, setArtSrc] = useState(src)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (artSrc !== src) {
      setIsLoading(true)
      setArtSrc(src)
    }
  }, [artSrc, src]);

  return (
    <div className={classes.card} onClick={onClick}>
      <div className={classes.cardImage}>
       {artSrc && mediaType ==='image' && (
          <PreloadImage
          onLoad={() => {setIsLoading(false)}}
          src={artSrc}
          alt={name}
          style={{
            position: "relative",
            height: "100%",
            width: "100%"
          }}
          ease="ease-in"
          duration="600ms"
          lazy
          />
        )}
        {artSrc && mediaType=== 'video' && (
          <video className={classes.videoThumb} autoPlay muted loop>
            <source src={artSrc} />
          </video>
        )}
        {artSrc && mediaType === 'website' && (
          <iframe title="website" className={classes.websiteThumb} src={artSrc} />
        )}

        {isLoading && (
          <div className={classes.cardLoading}>
            <CircularProgress />
          </div>
        )}
      </div>
      <Typography variant="h6">@{name}</Typography>
    </div>
  );
}

export default ChannelCard;
