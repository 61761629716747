import { Box, Typography } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";

import SquareSVG from "../../assets/svgs/squarebtn.svg";
import StarsSVG from "../../assets/svgs/stars.svg";

const GettingReady = (props) => {
  const deviceInfo = useSelector((state) => state.device.deviceInfo)

  return (
    <div {...props}>
      <img src={StarsSVG} alt="stars" />
      <Typography variant="h2">{deviceInfo.name} is ready!</Typography>
      <Typography variant="subtitle1">Waiting for artwork...</Typography>
      <Box
        border="2px solid #3A3A3A"
        borderRadius={12}
        padding="16px"
        maxWidth={340}
        mx="auto"
        mt="40px"
      >
        <Typography variant="body1">
          Click this button{" "}
          <img
            style={{ transform: "translateY(10px)" }}
            src={SquareSVG}
            alt="square"
          />{" "}
          on the artwork you want to display in this device.
        </Typography>
      </Box>
    </div>
  );
};

export default GettingReady;
