import { Box, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import React from "react";

import { channelsSelectors } from "../../redux/channels.slice";
import StarsSVG from "../../assets/svgs/stars.svg";
import { get } from "../../lib/functions";
import { useStyles } from "./styles";

import PreloadImage from "./PreloadImage";

const Final = (props) => {
  const deviceInfo = useSelector((state) => state.device.deviceInfo),
    channel = useSelector((state) => channelsSelectors.selectById(state, deviceInfo.channelId)),
    url = get(deviceInfo, "artwork.url", ""),
    mediaType = get(deviceInfo, "artwork.mediaType", ""),
    name = get(deviceInfo, "artwork.name", "Artwork"),
    classes = useStyles();

  return (
    <div {...props}>
      <img src={StarsSVG} alt="stars" />
      <Typography variant="h2">{deviceInfo.name} is ready!</Typography>
      <Box
        flexDirection="column"
        alignItems="center"
        display="flex"
        marginTop={3}
      >
        <Link to={`/artwork/${deviceInfo.id}`}>
          <Box className={classes.cardImage} border="3px solid #13C93B">
            {/* {url && <PreloadImage
              src={url}
              style={{
                position: "relative",
                height: "100%",
                width: "100%"
              }}
              ease="cubic-bezier(.4,.41,.71,.71)"
              duration="400ms"
              lazy
            />} */}
            {url && mediaType === 'image' && (
              <PreloadImage
                src={url}
                alt={name}
                style={{
                  position: "relative",
                  height: "100%",
                  width: "100%"
                }}
                ease="ease-in"
                duration="600ms"
                lazy
              />
            )}
            {url && mediaType === 'video' && (
              <video className={classes.videoThumb} autoPlay muted loop>
                <source src={url} />
              </video>
            )}
            {url && mediaType === 'website' && (
              <iframe title="website" className={classes.websiteThumb} src={url} />
            )}
          </Box>
        </Link>
        <Typography variant="h6">{channel ? `tuned to @${channel.name}` : 'pushed by you'}</Typography>
      </Box>
    </div>
  );
};

export default Final;
