import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
    in: {
        animation: "$fadeIn 3s ease-in"
    },
    out: {
        animation: "$fadeOut 3s ease-in"
    },
    "@keyframes fadeIn": {
      "0%": {
        opacity: "0",
      },
      "100%": {
        opacity: "1",
      }
    },
    "@keyframes fadeOut": {
        "0%": {
          opacity: "1",
        },
        "100%": {
          opacity: "0",
        }
      },
  });

const Fade = ({ show, onVisible, onHidden, children }) => {
    const [render, setRender] = useState(show);
    const classes = useStyles();

    useEffect(() => {
        if (show) setRender(true);
    }, [show]);

    const onAnimationEnd = () => {
        if (!show) {
            if (onHidden) onHidden()
            setRender(false)
        } else {
            if (onVisible) onVisible()
        }
    };

    return (
        render && (
            <div
                className={show ? classes.in : classes.out}
                // style={{ animation: `${show ? "fadeIn" : "fadeOut"} 1s` }}
                onAnimationEnd={onAnimationEnd}
            >
                {children}
            </div>
        )
    );
};

export default Fade;