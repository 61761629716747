import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router";
import store from '../../redux/store.js'
import { deviceActions } from "../../redux/device.slice";
import zeroFrameApi from "../../lib/zeroFrameApi";
import NoiseBackground from "./NoiseBackground";
import ArtworkPage from "../Artwork";
import Loading from "./Loading";
import Content from "./Content";

const HomePage = () => {
  const { deviceInfo, isLoading } = useSelector((state) => state.device),
    isDevicePaired = !!Object.keys(deviceInfo).length,
    isArtworkSection = !!useRouteMatch({
      path: "/artwork/:id",
      strict: true,
      exact: true,
    }),
    isHomeSection = !!useRouteMatch({
      strict: true,
      exact: true,
      path: "/",
    }),
    dispatch = useDispatch();

  const history = useHistory()
  const goToDevice = useCallback((deviceId) => history.push(`/artwork/${deviceId}`), [history]);

  /**
   * Get device in case if already paired
   */
  const fetchDevices = useCallback(() => {
    const deviceID = window.localStorage.getItem("DEVICE_ID");
    if (deviceID) {
      dispatch(deviceActions.setIsLoading(true))
      dispatch(deviceActions.getDevice(deviceID)).then(({ payload }) => {
        dispatch(deviceActions.setIsLoading(false))
        if (payload && payload.id) {
          zeroFrameApi.ps.subscribeToDeviceUpdated(payload.id, (data) => {
            const state = store.getState()
            const deviceInfo = state.device.deviceInfo
            if (deviceInfo.artwork && data.artwork && deviceInfo.artwork.url !== data.artwork.url) {
              // artwork has changed
              dispatch(deviceActions.setIsLoading(true))
              dispatch(deviceActions.updateDevice(data))
              goToDevice(payload.id)
              // setTimeout(() => {
              //   dispatch(deviceActions.updateDevice(data))
              //   goToDevice(payload.id)
              //   dispatch(deviceActions.setIsLoading(false))
              // }, 5000);
            } else {
              dispatch(deviceActions.updateDevice(data))
            }
          });
        }
        if (payload.artwork && payload.artwork.url) {
          goToDevice(payload.id)
        }
      }).catch(() => {
        dispatch(deviceActions.setIsLoading(false))
      });
    } else {
      dispatch(deviceActions.setIsLoading(false))
      dispatch(deviceActions.setCheckPaired(false));
    }
  }, [dispatch, goToDevice]);

  /**
   * Fetch devices data
   */
  useEffect(() => {
    fetchDevices();
  }, [fetchDevices]);

  useEffect(() => {
    zeroFrameApi.ps.connect();
  }, []);



  return (
    <>
      <NoiseBackground active={isDevicePaired && isHomeSection} />

      {[
        {
          Component: Content,
          active: !isLoading && isHomeSection,
        },
        {
          Component: Loading,
          active: isLoading,
        },
        {
          Component: ArtworkPage,
          active: !isLoading && isArtworkSection,
        },
      ].map(({ Component, active }, i) => (
        <Component className={[active ? "active" : ""].join(" ")} key={i} active={active} />
      ))}
    </>
  );
};

export default HomePage;
