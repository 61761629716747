import { useStyles } from "./styles";

import noiseImage from "../../assets/images/noisebg.png";

new Image().src = noiseImage;

const NoiseBackground = ({ active }) => {
  const classes = useStyles();

  return (
    <div className={[classes.bgNoise, active ? "active" : ""].join(" ")}>
      <img src={noiseImage} alt="noise background" />
    </div>
  );
};

export default NoiseBackground;
