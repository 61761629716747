import { createSlice, createAsyncThunk, createEntityAdapter } from "@reduxjs/toolkit";
import zeroFrameApi from "../lib/zeroFrameApi";

const PREFIX = "channels";

const adapter = createEntityAdapter({
    selectId: (channel) => channel.id,
});

export const getPublicChannels = createAsyncThunk(
    `${PREFIX}/get_public_channels`, async (nada, thunkAPI) => {
        return await zeroFrameApi.channels.fetch({
            where: { "access": "public" }
        })
    });

const slice = createSlice({
    name: PREFIX,

    initialState: adapter.getInitialState({
        isLoading: true,
        hasError: false
        // publicChannels: {
        //     isLoading: true,
        //     hasError: false,
        //     data: []
        // },
    }),
    reducers: {
        channelsLoading(state, action) {
            state.isLoading = true
        },
        channelsReceived(state, action) {
            state.isLoading = false
            adapter.setAll(state, action.payload)
        },
        channelUpdated: adapter.updateOne,
    },
    extraReducers: {
        /**
         * Get public channels
         */
        [getPublicChannels.pending]: (state, action) => {
            state.isLoading = true
            // state.publicChannels = { ...state.publicChannels, isLoading: true, hasError: false };
        },
        [getPublicChannels.fulfilled]: (state, { payload }) => {
            // state.publicChannels = { data, isLoading: false };
            state.isLoading = false
            adapter.setAll(state, payload)
        },
        [getPublicChannels.rejected]: (state, action) => {
            state.isLoading = false
            state.hasError = true
            // state.publicChannels = { ...state.publicChannels, isLoading: false, hasError: true };
        },
    },
});

const channelsSelectors = adapter.getSelectors(state => state.channels)

const { reducer: channelsReducer, actions } = slice;
const channelsActions = {
    ...actions,
    getPublicChannels
}

export { channelsReducer, channelsActions, channelsSelectors };
