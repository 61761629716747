import { Box } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React from "react";
import { useStyles } from "./styles";

function SkeletonLoader() {
    const classes = useStyles();

    return (
        <>
            <Box className={classes.card}>
                <Skeleton
                    variant="rect"
                    width={124}
                    height={124}
                    className={classes.skeleton}
                />

                <Skeleton
                    width={124}
                    height={20}
                    style={{ marginTop: 20 }}
                    className={classes.skeleton}
                />
            </Box>
            <Box className={classes.card}>
                <Skeleton
                    variant="rect"
                    width={124}
                    height={124}
                    className={classes.skeleton}
                />

                <Skeleton
                    width={124}
                    height={20}
                    style={{ marginTop: 20 }}
                    className={classes.skeleton}
                />
            </Box>
            <Box className={classes.card}>
                <Skeleton
                    variant="rect"
                    width={124}
                    height={124}
                    className={classes.skeleton}
                />

                <Skeleton
                    width={124}
                    height={20}
                    style={{ marginTop: 20 }}
                    className={classes.skeleton}
                />
            </Box>
        </>
    );
}

export default SkeletonLoader;
