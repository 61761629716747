import { ThemeProvider } from "@material-ui/core";
import React, { useCallback, useEffect } from "react";
import { useHistory, /*useParams*/ } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { deviceActions } from "../../redux/device.slice";

import { useStyles, customTheme } from "./styles";
import { get } from "../../lib/functions";
import GUI from "./GUI";
import ImageFormat from "./ImageFormat";
import VideoFormat from "./VideoFormat";
import WebsiteFormat from "./WebsiteFormat";

const ArtworkPage = ({ className, active }) => {
  const deviceInfo = useSelector((state) =>
    get(state, "device.deviceInfo", {})
  ),
    // channels = useSelector((state) =>
    //   get(state, "channels.publicChannels.data", [])
    // ),
    history = useHistory(),
    dispatch = useDispatch(),
    classes = useStyles();

  // const getArtwork = (() =>
  //   [deviceInfo, ...channels]
  //     .map((item) => ({ [item.id]: item.artwork }))
  //     .reduce((p, n) => ({ ...p, ...n }), {})[id] || {})();

  // const { title, owner, creatorName, url, mediaType } = deviceInfo.artwork;
  const artwork = deviceInfo.artwork || {};

  /**
   * Back to home page
   */
  const backToHomePage = useCallback(() => history.push("/"), [history]);

  /**
   * Check if url exists
   */
  useEffect(() => {
    if (!artwork.url) {
      backToHomePage();
      return
    }
    // eslint-disable-next-line
  }, [artwork.url]);

  const onArtworkReady = () => {
    setTimeout(() => {
      dispatch(deviceActions.setIsLoading(false))
    }, 1000)
  }

  return (
    <ThemeProvider theme={customTheme}>
      <div className={[classes.wrapper, className].join(" ")}>
        {artwork.url && artwork.mediaType === 'image' && (
          <ImageFormat url={artwork.url} onArtworkReady={onArtworkReady} />
        )}
        {artwork.url && artwork.mediaType === 'video' && (
          <VideoFormat url={artwork.url} onArtworkReady={onArtworkReady} />
          )}
        {artwork.url && artwork.mediaType === 'website' && (
          <WebsiteFormat url={artwork.url} onArtworkReady={onArtworkReady} />
        )}

        <GUI artwork={artwork} classes={classes} backToHomePage={backToHomePage} />
      </div>
    </ThemeProvider>
  );
};

export default ArtworkPage;
