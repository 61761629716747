import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import zeroFrameApi from "../lib/zeroFrameApi";

const PREFIX = "DEVICE";

const pairDevice = createAsyncThunk(`${PREFIX}_pair_device`, async (code) => {
  return await zeroFrameApi.devices.pair(code)
});

const getDevice = createAsyncThunk(`${PREFIX}_get_device_by_id`, async (deviceID) => {
  return await zeroFrameApi.devices.fetchById(deviceID)
});

const slice = createSlice({
  name: PREFIX,
  initialState: {
    pairing: {
      isPairing: false,
      errorMessage: "",
      hasError: false,
    },
    checkPaired: true,
    deviceInfo: {},
    isLoading: true
  },
  reducers: {
    updateDeviceArtwork: (state, { payload }) => {
      state.deviceInfo.artwork = payload;
    },
    updateDevice: (state, { payload }) => {
      state.deviceInfo = payload;
    },
    setCheckPaired: (state, { payload }) => {
      state.checkPaired = payload;
    },
    setIsLoading: (state, { payload }) => {
      state.isLoading = payload
    }
  },
  extraReducers: {
    /**
     * Pair device
     */
    [pairDevice.pending]: (state) => {
      state.pairing = { isPairing: true, errorMessage: "", hasError: false };
    },
    [pairDevice.fulfilled]: (state, { payload }) => {
      window.localStorage.setItem("DEVICE_ID", payload.id);
      state.pairing.isPairing = false;
      state.deviceInfo = payload;
    },
    [pairDevice.rejected]: (state, action) => {
      state.pairing = { isPairing: false, errorMessage: action.error.message, hasError: true };
    },

    /**
     * Get device
     */
    [getDevice.pending]: (state) => {
      state.pairing = { isPairing: true, errorMessage: "", hasError: false };
      state.checkPaired = true;
    },
    [getDevice.fulfilled]: (state, { payload }) => {
      state.pairing.isPairing = false;
      state.deviceInfo = payload;
      state.checkPaired = false;
    },
    [getDevice.rejected]: (state, action) => {
      state.pairing = { isPairing: false, errorMessage: action.error.message, hasError: true };
      state.checkPaired = false;
    },
  }
});

const { reducer: deviceReducer, actions } = slice;
const deviceActions = {
  ...actions,
  pairDevice,
  getDevice
}

export { deviceReducer, deviceActions }