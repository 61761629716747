import { createMuiTheme, makeStyles } from "@material-ui/core";

import "@fontsource/nunito-sans/700.css";
import "@fontsource/nunito-sans/400.css";

export const useStyles = makeStyles((theme) => {
    return {
        wrapper: {
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundColor: "#000",
            backgroundSize: "cover",
            transition: "all .4s",
            position: "absolute",
            visibility: "hidden",
            overflow: 'hidden',
            height: "100vh",
            width: "100%",
            opacity: 0,
            right: 0,
            left: 0,
            top: 0,

            "&.active": {
                visibility: "visible",
                position: "relative",
                opacity: 1,
            }
        },
        image: {
            position: "fixed",
            height: "100%",
            width: "100%",
            left: 0,
            top: 0,
            backgroundSize: "cover",
            backgroundPosition: "center center",
            backgroundRepeat: "no-repeat"
        },
        video: {
            objectFit: 'cover',
            width: '100vw',
            height: '100vh',
            position: 'fixed',
            top: 0,
            left: 0
        },
        videoWrap: {
            width: '100vw',
            height: '100vh',
            position: 'fixed',
            top: 0,
            left: 0,
            background: '#000000'
        },
        website: {
            border: 0,
            width: '100vw',
            height: '100vh',
            position: 'fixed',
            top: 0,
            left: 0,
        },
        gui: {
            position: "absolute",
            overflow: 'hidden',
            height: "100vh",
            width: "100%",
            right: 0,
            left: 0,
            top: 0,
        },
        closeButton: {
            height: theme.spacing(6),
            width: theme.spacing(6),
            padding: "0 !important",
            right: theme.spacing(2),
            top: theme.spacing(2),
            position: "absolute",
            background: "black",
            borderRadius: "50%",
            color: "white",
            fontSize: 26,
            minWidth: 0,
            "&:hover": {
                background: "rgba(0,0,0,0.85)",
            },
            transition: "opacity 400ms ease-out"
        },

        detailsCard: {
            background: "rgba(0,0,0,0.85)",
            color: "white",
            position: "absolute",
            left: theme.spacing(2),
            bottom: theme.spacing(2),
            // width: "100%",
            // maxWidth: 250,
            // display: 'inline',
            borderRadius: 10,
            padding: "20px 20px 20px 20px",
            transition: "opacity 400ms ease-out"
        },
    };
}, {index: 1});

export const customTheme = createMuiTheme({
    typography: {
        h3: {
            fontSize: 20,
            fontWeight: 700,
            color: "#ffffff",
            marginBottom: 10,
        },

        subtitle1: {
            fontSize: 15,
            color: "#ffffff",
            fontWeight: 400,
        },
    },
});
