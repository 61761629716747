import { Typography, Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { IoCloseSharp } from "react-icons/io5";


function GUI({ artwork, classes, backToHomePage }) {
    const [showUI, setShowUI] = useState(true),
        [timer, setTimer] = useState(null);
    // on click show X and plaque
    const handleClick = (e) => {
        doShowUI()
    }

    const handleCloseClick = () => {
        clearTimeout(timer)
        backToHomePage()
    }

    const doShowUI = () => {
        setShowUI(true)
        clearTimeout(timer)
        const localTimer = setTimeout(() => setShowUI(false), 3000)
        setTimer(localTimer)
        return localTimer
    }

    // First render, setTimeout to hide UI after 3s
    useEffect(() => {
        const curTimer = doShowUI()
        return () => clearTimeout(curTimer)
        // eslint-disable-next-line
    }, [])

    return (
        <div className={`gui ${classes.gui}`} onClick={handleClick}>
            {/* UI Elements */}
            <Button style={{ opacity: (showUI ? 1 : 0) }} className={classes.closeButton} onClick={handleCloseClick}>
                <IoCloseSharp />
            </Button>

            {(artwork.title || artwork.owner || artwork.creator) && (
                <div className={classes.detailsCard} style={{ opacity: (showUI ? 1 : 0) }}>
                    {artwork.title && <Typography variant="h3" style={{ marginBottom: 0 }}>{artwork.title}</Typography>}
                    {artwork.creator && (
                        <Typography variant="subtitle1">By {artwork.creator}</Typography>
                    )}
                    {artwork.owner && (
                        <Typography variant="subtitle1">Owned by {artwork.owner}</Typography>
                    )}
                </div>
            )}
        </div>
    );
}

export default GUI;
