export const get = (object = {}, keys = "", alt = null) => {
  try {
    keys.split(".").forEach(key => {
      if (object.hasOwnProperty(key)) object = object[key];
      else throw new Error()
    })
    return object;
  }
  catch (e) {
    return alt;
  }
}

export const checkImageOrientation = ({ target }) => {
  const { naturalHeight, naturalWidth } = target;
  target.setAttribute("data-portrait", Number(naturalHeight > naturalWidth));
}

export const toggleFullscreen = () => {
  if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
  } else {
      if (document.exitFullscreen) {
          document.exitFullscreen();
      }
  }
}

export const preloadImage = (src, cb, errcb) => {
  return new Promise((resolve, reject) => {
      let img = new Image();
      img.onload = () => {
          if (cb) {
              cb()
          }
          resolve(img);
      };
      img.onerror = () => {
          if (errcb) {
              errcb()
          }
          reject(img)
      }
      img.src = src;
  });
}