import { createMuiTheme, CssBaseline, ThemeProvider } from "@material-ui/core";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import React, { useEffect } from "react";
import { toggleFullscreen } from './lib/functions'

import HomePage from "./pages/Home";
import Pair from "./pages/Pair";

const themeDark = createMuiTheme({
  palette: {
    background: {
      default: "#000",
    },
    text: {
      primary: "#ffffff",
    },
  },
});

const App = () => {

  const downHandler = (e) => {
		if (e.key === "Enter") {
			toggleFullscreen();
		}
  }

  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    return () => {
      window.removeEventListener('keydown', downHandler);
    };
  }, []); // Empty array ensures that effect is only run on mount and unmount


  return (
    <BrowserRouter>
      <ThemeProvider theme={themeDark}>
        <CssBaseline />
        <Switch>
          <Route component={HomePage} path="/" strict exact />
          <Route component={Pair} path="/pair/:code" strict exact />
          <Route component={HomePage} path="/artwork/:id" strict exact />
          <Redirect to="/" />
        </Switch>
      </ThemeProvider>
    </BrowserRouter>
  );
};

export default App;
