import React from "react";
import { useStyles } from "./styles";

const VideoFormat = ({ url, onArtworkReady }) => {
    const classes = useStyles()

    onArtworkReady()

    return (
        <iframe title="website" className={classes.website} src={url} />
    );
};

export default VideoFormat;
